import { NewsItem } from 'interfaces/News/News';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

const articles = [
  {
    previewImage: 'tripod/tripod-main-image.jpg',
    id: 'tripod',
    link: 'tripod-for-using-idchess',
    date: [17, 1, 2024],
    filters: ['instructions'],
    lang: ['ru', 'en'],
  },
  {
    previewImageLocale: {
      ru: 'smartphone/smartphone-main-image.jpg',
      en: 'smartphone/smartphone-main-image-en.jpg',
    },
    id: 'smartphone',
    link: 'smartphone-for-using-idchess',
    date: [26, 1, 2024],
    filters: ['instructions'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'var_history/var_history-main-image.jpg',
    id: 'var_history',
    link: 'var-in-different-kinds-of-sport',
    date: [9, 2, 2024],
    filters: ['stories'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'var_chess/var_chess-main-image.jpg',
    id: 'var_chess',
    link: 'var-in-chess',
    date: [20, 2, 2024],
    filters: ['tips'],
    lang: ['ru', 'en'],
  },
  {
    previewImageLocale: {
      ru: 'time_control/time-control-ru-min.jpg',
      en: 'time_control/time-control-en-min.jpg',
    },
    id: 'time_control',
    link: 'time-control-instruction',
    date: [7, 4, 2024],
    filters: ['instructions'],
    lang: ['ru', 'en'],
  },
  {
    previewImageLocale: {
      ru: 'join/join-ru-min.jpg',
      en: 'join/join-en-min.jpg',
    },
    id: 'join',
    link: 'join-the-live',
    date: [21, 3, 2024],
    filters: ['instructions'],
    lang: ['ru', 'en'],
  },
  // TODO: Контент статьи 8. Ждем лакализацию изображений для английской версии
  // {
  //   previewImage: 'bots/bots-1.jpg',
  //   id: 'bots',
  //   link: 'bots-idchess',
  //   date: [8, 5, 2024],
  //   filters: ['instructions'],
  //   lang: ['ru', 'en'],
  // },
  {
    previewImageLocale: {
      ru: 'start_tournament/start_tournament-main-image-ru-min.jpg',
      en: 'start_tournament/start_tournament-main-image-en-min.jpg',
    },
    id: 'start_tournament',
    link: 'start-tournament-live-idchess',
    date: [14, 4, 2024],
    filters: ['instructions'],
    lang: ['ru', 'en'],
  },
];

export const sortedArticles = articles => {
  if (!articles?.length) {
    return [];
  }

  return articles.sort((prev, next) => {
    if (
      !prev?.date ||
      !next?.date ||
      !(prev.date?.length >= 3 && next.date?.length >= 3)
    ) {
      return 0;
    }

    const prevDate = new Date(prev.date[2], prev.date[1], prev.date[0]);
    const nextDate = new Date(next.date[2], next.date[1], next.date[0]);

    if (nextDate > prevDate) {
      return 1;
    }

    if (nextDate < prevDate) {
      return -1;
    }

    return 0;
  });
};

export const articlesFilters = [
  // фильтры по которым пока нет статей закомментированы, чтобы они не отображались на странице
  'instructions',
  'tips',
  'stories',
  // 'tournaments',
];

const articlesContent = {
  common: {
    tripod: [
      {
        type: 'image',
        image: 'tripod/tripod-main-image.jpg',
        alt: 'blog.items.tripod.title',
      },
      {
        type: 'subtitle',
        text: ['blog.items.tripod.subtitle.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.1',
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.2',
      },
      {
        type: 'slider',
        images: ['tripod/tripod-slider1-1.jpg', 'tripod/tripod-slider1-2.jpg'],
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.1'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.3',
      },
      {
        type: 'imagesLine',
        images: ['tripod/tripod-pack-1.jpg', 'tripod/tripod-pack-2.jpg'],
      },
      {
        type: 'imagesLine',
        images: ['tripod/tripod-pack-3.jpg', 'tripod/tripod-pack-4.jpg'],
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.2'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.4',
      },
      {
        type: 'slider',
        images: ['tripod/tripod-slider2-1.jpg', 'tripod/tripod-slider2-2.jpg'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.tripod.text.3',
            },
            {
              text: 'blog.items.tripod.text.4',
              link: 'https://idchess.com/ru/products/16',
            },
            {
              text: 'blog.items.tripod.text.5',
            },
          ],
        ],
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.6'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.tripod.text.7',
            },
            {
              text: 'blog.items.tripod.text.8',
            },
            {
              text: 'blog.items.tripod.text.9',
              link: 'mailto:suppot@idchess.com',
            },
          ],
        ],
      },
    ],
    smartphone: [
      {
        type: 'image',
        imageLocale: {
          ru: 'smartphone/smartphone-main-image.jpg',
          en: 'smartphone/smartphone-main-image-en.jpg',
        },
        alt: 'blog.items.smartphone.title',
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.0'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.smartphone.subtitle.1',
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'smartphone/smartphone-1.jpg',
          en: 'smartphone/smartphone-1-en.jpg',
        },
        alt: 'blog.items.smartphone.subtitle.1',
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.2'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.1', 'blog.items.smartphone.text.2'],
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.3'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.3'],
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.4'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.4'],
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.5'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.5'],
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.6'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.6'],
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.7'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.7'],
      },
      {
        type: 'results',
        isBold: true,
        line: true,
        results: [
          {
            text: 'blog.items.smartphone.results.options.0.name',
            rating: 'blog.items.smartphone.results.options.0.rating',
          },
          {
            text: 'blog.items.smartphone.results.options.1.name',
            rating: 'blog.items.smartphone.results.options.1.rating',
          },
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.smartphone.subtitle.8',
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'smartphone/smartphone-2.jpg',
          en: 'smartphone/smartphone-2-en.jpg',
        },
        alt: 'blog.items.smartphone.subtitle.8',
      },
      {
        type: 'text',
        text: [
          'blog.items.smartphone.text.8',
          'blog.items.smartphone.text.9',
          'blog.items.smartphone.text.10',
        ],
      },
    ],
    var_history: [
      {
        type: 'image',
        image: 'var_history/var_history-main-image.jpg',
        alt: 'blog.items.var_history.title',
      },
      {
        type: 'subtitle',
        text: ['blog.items.var_history.subtitle.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.1',
      },
      {
        type: 'image',
        image: 'var_history/var_history-1.jpg',
        alt: 'blog.items.var_history.subtitle.1',
      },
      {
        type: 'text',
        text: [
          'blog.items.var_history.text.0',
          'blog.items.var_history.text.1',
          'blog.items.var_history.text.2',
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.2',
      },
      {
        type: 'image',
        image: 'var_history/var_history-2.jpg',
        alt: 'blog.items.var_history.subtitle.2',
      },
      {
        type: 'text',
        text: [
          'blog.items.var_history.text.3',
          'blog.items.var_history.text.4',
          'blog.items.var_history.text.5',
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.3',
      },
      {
        type: 'image',
        image: 'var_history/var_history-3.jpg',
        alt: 'blog.items.var_history.subtitle.3',
      },
      {
        type: 'text',
        text: [
          'blog.items.var_history.text.6',
          'blog.items.var_history.text.7',
          'blog.items.var_history.text.8',
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.4',
      },
      {
        type: 'image',
        image: 'var_history/var_history-4.jpg',
        alt: 'blog.items.var_history.subtitle.4',
      },
      {
        type: 'text',
        text: [
          'blog.items.var_history.text.9',
          'blog.items.var_history.text.10',
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.5',
      },
      {
        type: 'text',
        text: ['blog.items.var_history.text.11'],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.6',
      },
      {
        type: 'image',
        image: 'var_history/var_history-5.jpg',
        alt: 'blog.items.var_history.subtitle.5',
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.var_history.text.12',
            },
            {
              text: 'blog.items.var_history.text.13',
              link: 'https://www.fide.com/news/2491',
            },
            {
              text: 'blog.items.var_history.text.14',
            },
          ],
        ],
      },
      {
        type: 'text',
        text: ['blog.items.var_history.text.15'],
      },
      {
        type: 'image',
        image: 'var_history/var_history-6.jpg',
        alt: 'blog.items.var_history.subtitle.6',
      },
      {
        type: 'text',
        text: ['blog.items.var_history.text.16'],
      },
      {
        type: 'image',
        image: 'var_history/var_history-7.jpg',
        alt: 'blog.items.var_history.subtitle.7',
      },
      {
        type: 'text',
        text: ['blog.items.var_history.text.17'],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.7',
      },
      {
        type: 'text',
        text: ['blog.items.var_history.text.18'],
      },
    ],
    var_chess: [
      {
        type: 'subtitle',
        text: ['blog.items.var_chess.subtitle.0'],
      },
      {
        type: 'image',
        image: 'var_chess/var_chess-main-image.jpg',
        alt: 'blog.items.var_chess.title',
      },
      {
        type: 'h2',
        title: 'blog.items.var_chess.subtitle.1',
      },
      {
        type: 'text',
        text: ['blog.items.var_chess.text.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.var_chess.subtitle.2',
      },
      {
        type: 'text',
        text: [
          'blog.items.var_chess.text.1',
          'blog.items.var_chess.text.2',
          'blog.items.var_chess.text.3',
          'blog.items.var_chess.text.4',
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.var_chess.subtitle.3',
      },
      {
        type: 'text',
        text: [
          'blog.items.var_chess.text.5',
          'blog.items.var_chess.text.6',
          'blog.items.var_chess.text.7',
        ],
      },
      {
        type: 'quote',
        text: ['blog.items.var_chess.quote.text'],
        name: 'blog.items.var_chess.quote.name',
        position: 'blog.items.var_chess.quote.place',
      },
    ],
    time_control: [
      {
        type: 'subtitle',
        text: ['blog.items.time_control.subtitle.0'],
      },
      {
        type: 'image',
        alt: 'blog.items.time_control.title',
        imageLocale: {
          ru: 'time_control/time-control-ru.jpg',
          en: 'time_control/time-control-en.jpg',
        },
      },
      {
        type: 'text',
        text: [
          'blog.items.time_control.text.0',
          'blog.items.time_control.text.1',
        ],
      },
      {
        type: 'results',
        isBold: true,
        line: true,
        results: [
          {
            text: 'blog.items.time_control.results.options.0.name',
            rating: 'blog.items.time_control.results.options.0.rating',
          },
          {
            text: 'blog.items.time_control.results.options.1.name',
            rating: 'blog.items.time_control.results.options.1.rating',
          },
        ],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.time_control.text.2',
            },
            {
              text: 'blog.items.time_control.links.0',
              link: 'https://youtu.be/rJEO7dam_pM?si=AjhUh_xagOqdfvWz',
            },
            {
              text: 'blog.items.time_control.text.3',
            },
            {
              text: 'blog.items.time_control.links.1',
              link: 'https://www.youtube.com/@idchess4872',
            },
            {
              text: 'blog.items.time_control.text.4',
            },
          ],
        ],
      },
    ],
    join: [
      {
        type: 'subtitle',
        text: ['blog.items.join.subtitle.0'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.join.text.0',
            },
            {
              text: 'blog.items.join.link',
              link: 'http://live.idchess.com',
            },
            {
              text: 'blog.items.join.text.1',
            },
          ],
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.join.subtitle.1',
      },
      {
        type: 'image',
        alt: 'blog.items.join.title',
        imageLocale: {
          ru: 'join/join-ru.jpg',
          en: 'join/join-en.jpg',
        },
      },
      {
        type: 'text',
        text: ['blog.items.join.text.2', 'blog.items.join.text.3'],
      },
      {
        type: 'results',
        isBold: true,
        line: false,
        results: [
          {
            text: 'blog.items.join.results.name',
            rating: 'blog.items.join.results.rating',
          },
        ],
      },
      {
        type: 'text',
        text: ['blog.items.join.text.4'],
      },
    ],
    // TODO: Контент статьи 8. Ждем лакализацию изображений для английской версии
    // bots: [
    //   {
    //     type: 'subtitle',
    //     text: ['blog.items.bots.subtitle.0'],
    //   },
    //   {
    //     type: 'image',
    //     image: 'bots/bots-1.jpg',
    //     alt: 'blog.items.bots.title',
    //   },
    //   {
    //     type: 'text',
    //     text: ['blog.items.bots.text.0'],
    //   },
    //   {
    //     type: 'image',
    //     image: 'bots/bots-2.jpg',
    //     alt: 'blog.items.bots.description',
    //   },
    //   {
    //     type: 'text',
    //     text: ['blog.items.bots.text.1'],
    //   },
    //   {
    //     type: 'image',
    //     image: 'bots/bots-3.jpg',
    //     alt: 'blog.items.bots.subtitle.1',
    //   },
    //   {
    //     type: 'subtitle',
    //     text: ['blog.items.bots.subtitle.1'],
    //   },
    //   {
    //     type: 'text',
    //     text: ['blog.items.bots.text.2'],
    //   },
    //   {
    //     type: 'image',
    //     image: 'bots/bots-4.jpg',
    //     alt: 'blog.items.bots.text.0',
    //   },
    //   {
    //     type: 'results',
    //     isBold: true,
    //     line: false,
    //     results: [
    //       {
    //         text: 'blog.items.bots.results.name',
    //         rating: 'blog.items.bots.results.rating',
    //       },
    //     ],
    //   },
    //   {
    //     type: 'image',
    //     image: 'bots/bots-5.jpg',
    //     alt: 'blog.items.bots.text.1',
    //   },
    //   {
    //     type: 'text',
    //     textWithLink: [
    //       [
    //         {
    //           text: 'blog.items.bots.text.3',
    //         },
    //         {
    //           text: 'blog.items.bots.text.4',
    //           link: 'https://idchess.com/app',
    //         },
    //         {
    //           text: 'blog.items.bots.text.5',
    //         },
    //       ],
    //     ],
    //   },
    // ],
    start_tournament: [
      {
        type: 'subtitle',
        text: ['blog.items.start_tournament.subtitle.0'],
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'start_tournament/start_tournament-1-ru.jpg',
          en: 'start_tournament/start_tournament-1-en.jpg',
        },
        alt: 'blog.items.start_tournament.subtitle.0',
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.start_tournament.text.0',
            },
            {
              text: 'blog.items.start_tournament.text.1',
              link: 'https://live.idchess.com/app/main',
            },
            {
              text: 'blog.items.start_tournament.text.2',
            },
          ],
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.start_tournament.subtitle.1',
      },
      {
        type: 'text',
        text: [
          'blog.items.start_tournament.list.0',
          'blog.items.start_tournament.list.1',
          'blog.items.start_tournament.list.2',
        ],
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'start_tournament/start_tournament-2-ru.jpg',
          en: 'start_tournament/start_tournament-2-en.jpg',
        },
        alt: 'blog.items.start_tournament.subtitle.0',
      },
      {
        type: 'text',
        text: ['blog.items.start_tournament.list.3'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.start_tournament.list.4',
            },
            {
              text: 'blog.items.start_tournament.list.5',
              link: 'https://idchess.com/ru/blog/time-control-instruction',
            },
            {
              text: 'blog.items.start_tournament.list.6',
            },
            {
              text: 'blog.items.start_tournament.list.7',
              link: 'https://youtu.be/rJEO7dam_pM?si=AjhUh_xagOqdfvWz',
            },
            {
              text: 'blog.items.start_tournament.list.8',
            },
          ],
        ],
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'start_tournament/start_tournament-3-ru.jpg',
          en: 'start_tournament/start_tournament-3-en.jpg',
        },
        alt: 'blog.items.start_tournament.subtitle.0',
      },
      {
        type: 'text',
        text: [
          'blog.items.start_tournament.list.9',
          'blog.items.start_tournament.list.10',
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.start_tournament.subtitle.2',
      },
      {
        type: 'text',
        text: ['blog.items.start_tournament.text.3'],
      },
      {
        type: 'h2',
        title: 'blog.items.start_tournament.subtitle.3',
      },
      {
        type: 'text',
        text: ['blog.items.start_tournament.text.4'],
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'start_tournament/start_tournament-4-ru.jpg',
          en: 'start_tournament/start_tournament-4-en.jpg',
        },
        alt: 'blog.items.start_tournament.subtitle.0',
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.start_tournament.text.5',
            },
            {
              text: 'blog.items.start_tournament.text.6',
              link: 'https://youtu.be/f4-USi7AMdw?si=sUO1x7G4UpniVVIS',
            },
            {
              text: 'blog.items.start_tournament.text.7',
            },
            {
              text: 'blog.items.start_tournament.text.8',
              link: 'https://www.youtube.com/@idchess4872',
            },
            {
              text: 'blog.items.start_tournament.text.9',
            },
          ],
        ],
      },
    ],
  },
  en: {
    tripod: [
      {
        type: 'image',
        image: 'tripod/tripod-main-image.jpg',
        alt: 'blog.items.tripod.title',
      },
      {
        type: 'subtitle',
        text: ['blog.items.tripod.subtitle.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.1',
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.2',
      },
      {
        type: 'slider',
        images: [
          'tripod/tripod-slider1-1-en.jpg',
          'tripod/tripod-slider1-2.jpg',
        ],
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.1', 'blog.items.tripod.text.2'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.3',
      },
      {
        type: 'imagesLine',
        images: ['tripod/tripod-pack-1.jpg', 'tripod/tripod-pack-2.jpg'],
      },
      {
        type: 'imagesLine',
        images: ['tripod/tripod-pack-3.jpg', 'tripod/tripod-pack-4-en.jpg'],
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.3'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.tripod.text.4',
            },
            {
              text: 'blog.items.tripod.text.5',
            },
            {
              text: 'blog.items.tripod.text.6',
              link: 'mailto:suppot@idchess.com',
            },
          ],
        ],
      },
    ],
    start_tournament: [
      {
        type: 'subtitle',
        text: ['blog.items.start_tournament.subtitle.0'],
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'start_tournament/start_tournament-1-ru.jpg',
          en: 'start_tournament/start_tournament-1-en.jpg',
        },
        alt: 'blog.items.start_tournament.subtitle.0',
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.start_tournament.text.0',
            },
            {
              text: 'blog.items.start_tournament.text.1',
              link: 'https://live.idchess.com/app/main',
            },
            {
              text: 'blog.items.start_tournament.text.2',
            },
          ],
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.start_tournament.subtitle.1',
      },
      {
        type: 'text',
        text: [
          'blog.items.start_tournament.list.0',
          'blog.items.start_tournament.list.1',
          'blog.items.start_tournament.list.2',
        ],
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'start_tournament/start_tournament-2-ru.jpg',
          en: 'start_tournament/start_tournament-2-en.jpg',
        },
        alt: 'blog.items.start_tournament.subtitle.0',
      },
      {
        type: 'text',
        text: ['blog.items.start_tournament.list.3'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.start_tournament.list.4',
            },
            {
              text: 'blog.items.start_tournament.list.5',
              link: 'https://idchess.com/blog/time-control-instruction',
            },
            {
              text: 'blog.items.start_tournament.list.6',
            },
            {
              text: 'blog.items.start_tournament.list.7',
              link: 'https://youtu.be/rJEO7dam_pM?si=AjhUh_xagOqdfvWz',
            },
            {
              text: 'blog.items.start_tournament.list.8',
            },
          ],
        ],
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'start_tournament/start_tournament-3-ru.jpg',
          en: 'start_tournament/start_tournament-3-en.jpg',
        },
        alt: 'blog.items.start_tournament.subtitle.0',
      },
      {
        type: 'text',
        text: [
          'blog.items.start_tournament.list.9',
          'blog.items.start_tournament.list.10',
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.start_tournament.subtitle.2',
      },
      {
        type: 'text',
        text: ['blog.items.start_tournament.text.3'],
      },
      {
        type: 'h2',
        title: 'blog.items.start_tournament.subtitle.3',
      },
      {
        type: 'text',
        text: ['blog.items.start_tournament.text.4'],
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'start_tournament/start_tournament-4-ru.jpg',
          en: 'start_tournament/start_tournament-4-en.jpg',
        },
        alt: 'blog.items.start_tournament.subtitle.0',
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.start_tournament.text.5',
            },
            {
              text: 'blog.items.start_tournament.text.6',
              link: 'https://youtu.be/f4-USi7AMdw?si=sUO1x7G4UpniVVIS',
            },
            {
              text: 'blog.items.start_tournament.text.7',
            },
            {
              text: 'blog.items.start_tournament.text.8',
              link: 'https://www.youtube.com/@idchess4872',
            },
            {
              text: 'blog.items.start_tournament.text.9',
            },
          ],
        ],
      },
    ],
  },
};

export const getArticle = id => {
  const currentItem = articles.filter(item => item.link === id)[0];

  if (currentItem) {
    const data: NewsItem = {
      ...currentItem,
    };

    return {
      status: 200,
      data: data,
    };
  }

  return {
    status: 404,
  };
};

export const useGetCurrentArticles = () => {
  const router = useRouter();

  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const currentArticles = useMemo(
    () => articles?.filter(item => item.lang?.includes(currentLanguage)),
    [articles, currentLanguage]
  );

  const currentArticlesContent = useMemo(() => {
    return Object.keys(articlesContent.common)
      ?.filter(key => currentArticles.map(({ id }) => id).includes(key))
      .reduce((obj, key) => {
        obj[key] =
          articlesContent[currentLanguage]?.[key] ||
          articlesContent.common[key];
        return obj;
      }, {});
  }, [articlesContent, currentArticles]);

  return { articles: currentArticles, articlesContent: currentArticlesContent };
};
